import {ApiBase} from '@jetCommon/api/base.js';

export default class PayrollApiCommon extends ApiBase {
    static resourceName = 'payrolls';

    getByYearMonthlyPay(companyId, year, monthly_pay) {
        return this.apiGet(`get_by_year_monthly_pay/${companyId}/${year}/${monthly_pay}/`);
    }

    requestProcess(id, data) {
        return this.apiPost(`${id}/request_process/`, data);
    }

    sendToEmployees(id) {
        return this.apiPost(`${id}/send_to_employees/`);
    }

    stats(id) {
        return this.apiGet(`${id}/stats/`);
    }

    getPayrollCycleDocumentsDownloadUrl(id, includeCompanyDocuments = false, includeEmployeeDocuments = false) {
        return this.getApiUrlWithParams(`${id}/download_documents/`, {
            includeCompanyDocuments,
            includeEmployeeDocuments,
        });
    }

    getCalendar(params = {}, config = {}) {
        return this.apiGet('calendar/', {params, ...config});
    }
}
